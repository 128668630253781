import "./App.css";
import { StatsigProvider } from "statsig-react";
import { TodoWrapper } from "./Components/TodoWrapper";
import { CLIENT_SDK_KEY } from "./Constant";
import {useEffect, useState} from "react";
import { Login } from "./Components/Login";
import * as React from "react";

import {AnalyticsBrowser} from "@segment/analytics-next";
import {interceptor} from "./interceptor";

export const analytics = AnalyticsBrowser.load({writeKey: "SJP1DdzGgOeEKruwFKyhWAlMCYWRbAJa"})

/**
 * Entry point of application
 * and initializing the statsig sdk
 *
 * @returns
 */
function App() {
  /**
   * initialzing user
   */

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    interceptor();
    analytics.user().then(user => {
      analytics.identify(user.anonymousId())
      setUser({
        userID: user.anonymousId(),
      });
      setIsLoading(false);
    }).catch(() => setIsLoading(false))
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  /**
   * Setting the user and flag from the login details
   * @param {*} userName
   */
  const setUserDetails = (userName: string) => {
    let localUser = {
      userID: user.userID,
    };

    localStorage.setItem("user", JSON.stringify(localUser));
    localStorage.setItem("isLoggedIn", "true");
    setUser(localUser);
    setIsLoggedIn(true);
  };

  /**
   * logging out from todo app
   */
  const onLogout = () => {
    localStorage.setItem("user", JSON.stringify({}));
    localStorage.setItem("isLoggedIn", "false");
    setUser({});
    setIsLoggedIn(false);
  };

  if (isLoading) {
    return (
        <div className="App">
          Loading site
        </div>
    )
  }

  return (
    <div className="App">
     <StatsigProvider
        sdkKey={CLIENT_SDK_KEY}
        waitForInitialization={true}
        options={{
          environment: { tier: "production" },
        }}
        user={user}
      >
        {!isLoggedIn ? (
          <Login setUser={setUserDetails} />
        ) : (
          <TodoWrapper onLogout={onLogout} />
        )}
      </StatsigProvider>
    </div>
  );
}

export default App;
