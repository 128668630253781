import {analytics} from "./App";

export const interceptor = () => {
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
        let [resource, config] = args;
        const startTime = Date.now();
        const response = await originalFetch(resource, config);
        const endTime = Date.now();
        const latency = endTime - startTime;
        const blacklist = ["api.segment.io", "segment.com", "google-analytics", "tag-manager", "doubleclick.net"];
        const shouldIgnoreCall = blacklist.some((str) => resource.toString().includes(str))
        if (!shouldIgnoreCall){
            console.log("API call", resource, config)
            analytics.track("API Call", {
                url: resource,
                method: config?.method,
                latency
            })
        }
        return response;
    };
};