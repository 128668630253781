export const FEATURE_GATE_1 = "sample_feature_gate";
export const TODO_EDITED = "CLIENT_TODO_EDITED";
export const TODO_COMPLETED = "CLIENT_TODO_COMPLETED";
export const TODO_CREATED = "CLIENT_TODO_CREATED";
export const TODO_DELETED = "CLIENT_TODO_DELETED";
export const TODO_LAST_VIEWED = "CLIENT_TODO_LAST_VIEWED";
export const EXPERIMENT_SORTING = "item_sorting";
export const EXPERIMENT_BUTTONS = "button_a_or_b";
export const CLIENT_SDK_KEY = "client-GtSzZSImNIDcw7D7nK3qqyprjJ6F4QoI1JsMXa0pwtu";
export const DYNAMIC_CONFIG_WARNING_BANNER = "warning_banner"
export const LOGIN_TITLE = "Login to TODO"


